<script setup>
const snackbarStore = useSnackbarStore()
const { active, message, timeout, color, closeColor } = storeToRefs(snackbarStore)
</script>

<template>
  <v-snackbar v-model="active" :timeout="timeout" :color="color">
    {{ message}}
    <template v-slot:actions>
      <v-btn
          :color="closeColor"
          variant="text"
          @click="active = false"
      >
        OK
      </v-btn>
    </template>
  </v-snackbar>
</template>