<script setup>
const authStore = useAuthStore()
const router = useRouter()

const onlyEnableTermsAndConditions = computed(() => !authStore.$state.user?.hasAcceptedTermsOfService)
</script>

<template>
  <v-list-item
      v-if="authStore.hasServicePermission('authmanagement-roles', ['find_all', 'find_assigned'])"
      :disabled="onlyEnableTermsAndConditions"
      class="pt-3 pb-3 mb-0 ps-16"
      @click="router.push({ name: 'settings-roles' })"
      value="projects">
    Roles
  </v-list-item>
  <v-divider></v-divider>
</template>