import App from './App.vue'
import router from './router/index.js'
import {pinia} from './modules/pinia.js'
import vuetify from './plugins/vuetify.js'
import {AuthenticationManagement} from "@rhdhv/vue-authentication-middleware";
import {AuthorizationManagement, createAuthorizationManagementStore} from "@rhdhv/vue-authorization-middleware";
import DeleteDialog from "@/components/dialog/DeleteDialog.vue";
import * as Sentry from "@sentry/vue";

const { api, client } = useFeathers()
const snackbarStore = useSnackbarStore(pinia)

const useAuthorizationManagementRolesStore = createAuthorizationManagementStore(api, snackbarStore)
const authorizationManagementRolesStore = useAuthorizationManagementRolesStore(pinia)

import HeaderImage from '@/assets/header.jpg'
import HeaderImageLazy from '@/assets/header_lazy.jpg'

const app = createApp(App)

app.use(vuetify)
  .use(pinia)
  .use(router)
  .use(AuthenticationManagement, {
    appTitle: 'AIS',
    headerImage: HeaderImage,
    headerImageLazy: HeaderImageLazy,
    api: api,
    snackbarStore: useSnackbarStore(pinia),
    authStore: useAuthStore(pinia),
    apiBaseUrl: import.meta.env.VITE_APP_API_BASE_URL
  })
  .use(AuthorizationManagement, {
    DeleteDialog,
    authorizationManagementRolesStore,
    feathers: api,
    restClient: client,
    authStore: useAuthStore(pinia),
    apiBaseUrl: import.meta.env.VITE_APP_API_BASE_URL
  })
  // This must be improved probably
  .provide('authorizationManagementRolesStore', authorizationManagementRolesStore)
  .mount('#app')

Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    tracePropagationTargets: ["*"],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

export default app;