<script setup>
import {storeToRefs} from "pinia";
import ProjectMenu from "@/components/projects/ProjectMenu.vue";
import SettingsMenu from "@/components/settings/SettingsMenu.vue";

const uiStore = useUiStore()
const router = useRouter()
const route = useRoute()
const authStore = useAuthStore()
const projectStore = useProjectStore()

const { 'currentItem': currentProject } = storeToRefs(projectStore)
const { sideBarState } = storeToRefs(uiStore)
const group = ref(null)
const selectedItems = ref([])
const onlyEnableTermsAndConditions = computed(() => !authStore.$state.user?.hasAcceptedTermsOfService)

const currentRoute = computed({
  get() {
    return router.currentRoute?.value?.name || '';
  },
})

// Hide menu if user logs off and goes to logon screen
watch(() => authStore.$state.user, (newValue) => {
  if(!newValue){
    sideBarState.value = false
  } else {
    sideBarState.value = true
  }
})

watch(group, async () => {
  sideBarState.value = false
})

watch(currentRoute, (newValue) => {
  if (newValue !== 'project-index' && newValue.startsWith('project-')) {
    selectedItems.value = []
  }
})

onMounted(() => {
  if(!authStore.$state.user){
    sideBarState.value = false
  }
})
</script>
<template>
  <v-navigation-drawer
    v-model="sideBarState"
    v-if="sideBarState === true && !route.name?.startsWith('auth-')"
    location="left"
    :width="300"
  >
    <div class="d-flex flex-column justify-space-between fill-height">
      <v-list
        v-model:selected="selectedItems"
        density="compact"
        class="pa-0 d-flex flex-column justify-lg-space-between fill-height"
        :nav="true"
        :mandatory="true"
      >
        <div class="overflow-y-auto">
          <template v-if="authStore.isGlobalAdmin">
            <v-list-item
              :disabled="onlyEnableTermsAndConditions"
              class="pt-3 pb-3 mb-0" @click="router.push({ name: 'settings-index' })"
              prepend-icon="mdi-cog" value="settings"
            >
              Settings
            </v-list-item>
            <v-divider></v-divider>
            <div v-if="currentRoute.startsWith('settings-') && currentRoute !== 'settings-index'">
              <settings-menu></settings-menu>
            </div>
          </template>
          <template v-if="authStore.isGlobalAdmin">
            <v-list-item
              :disabled="onlyEnableTermsAndConditions"
              class="pt-3 pb-3 mb-0" @click="router.push({ name: 'user-index' })"
              prepend-icon="mdi-account" value="user-management">
              Users
            </v-list-item>
            <v-divider></v-divider>
          </template>
          <template v-if="authStore.hasServicePermission('projects', ['find_all', 'find_assigned'])">
            <v-list-item
              :disabled="onlyEnableTermsAndConditions"
              class="pt-3 pb-3 mb-0" @click="router.push({ name: 'project-index' })"
              prepend-icon="mdi-briefcase" value="projects">
              Projects
            </v-list-item>
            <v-divider></v-divider>
            <div v-if="currentRoute.startsWith('project-') && currentRoute !== 'project-index' && currentProject">
              <project-menu></project-menu>
            </div>
          </template>
          <template v-if="authStore.hasServicePermission('polygons', ['find_all', 'find_assigned'])">
            <v-list-item
              :disabled="onlyEnableTermsAndConditions"
              class="pt-3 pb-3 mb-0" @click="router.push({ name: 'polygon-index' })"
              prepend-icon="mdi-vector-polygon" value="polygons">
              All Polygons
            </v-list-item>
            <v-divider></v-divider>
          </template>
          <template v-if="authStore.hasServicePermission('data-exports', ['find_all', 'find_assigned'])">
            <v-list-item
              :disabled="onlyEnableTermsAndConditions"
              class="pt-3 pb-3 mb-0" @click="router.push({ name: 'exported-data-index' })"
              prepend-icon="mdi-file-export" value="data-export">
              Data Exports
            </v-list-item>
            <v-divider></v-divider>
          </template>
        </div>
        <div>
          <v-divider/>
          <v-list-item
            :disabled="onlyEnableTermsAndConditions"
            class="pt-3 pb-3 mb-0" @click="router.push({ name: 'about' })"
            prepend-icon="mdi-information" value="about">
            About
          </v-list-item>
          <v-divider/>
          <v-list-item
            :disabled="onlyEnableTermsAndConditions"
            class="pt-3 pb-3 mb-0" @click="router.push({ name: 'reference-projects' })"
            prepend-icon="mdi-bulletin-board" value="reference-projects">
            Reference Projects
          </v-list-item>
          <v-divider/>
          <v-list-item
            :disabled="onlyEnableTermsAndConditions"
            class="pt-3 pb-3 mb-0" @click="router.push({ name: 'proposals' })"
            prepend-icon="mdi-file-multiple" value="proposals">
            Proposals
          </v-list-item>
          <v-divider/>
          <v-list-item
            @click="router.push({ name: 'terms-and-conditions' })"
            prepend-icon="mdi-file-document" value="terms-and-conditions">
            Terms and Conditions
          </v-list-item>
          <v-divider/>
          <v-list-item
            @click="router.push({ name: 'help' })"
            prepend-icon="mdi-help-circle"
            value="help"
          >
            Help
          </v-list-item>
          <v-divider/>
        </div>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>