import { feathers } from '@feathersjs/feathers'
import rest from '@feathersjs/rest-client'
import authentication from "@feathersjs/authentication-client";

const apiBaseUrl = import.meta.env.VITE_APP_API_BASE_URL

const feathersApi = feathers()
const restClient = rest(apiBaseUrl).fetch(window.fetch.bind(window))
feathersApi.configure(restClient)
feathersApi.configure(authentication())
feathersApi.use('data-exports', restClient.service('data-exports'), {
	methods: ['find', 'get', 'create', 'patch', 'download']
})
feathersApi.use('ais-events', restClient.service('ais-events'), {
	methods: ['find', 'get', 'heatMap', 'coverageMap']
})
feathersApi.use('ais-events-near-realtime', restClient.service('ais-events-near-realtime'), {
	methods: ['find', 'get', 'liveViewer', 'heatMap', 'coverageMap']
})
export const api = feathersApi
export const client = restClient
