// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { VDataTable } from 'vuetify/labs/VDataTable'
import { VAlert } from "vuetify/components"; // Do not remove this line, it will break the alert boxes on externally loaded Vuetify modules

// Vuetify
import { createVuetify } from 'vuetify'

const rhdhvTheme = {
    dark: false,
    colors: {
        primary: '#004b7e',
        secondary: '#5085A5',
        anchor: '#8c9eff',
    }
}

export default createVuetify({
    components: {
        VDataTable,
        VAlert
    },
    theme: {
        defaultTheme: 'rhdhvTheme',
        themes: {
            rhdhvTheme,
        }
    },
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
})
