import { useAuth } from 'feathers-pinia'

export const useAuthStore = defineStore('auth', () => {
    const { api } = useFeathers()
    const authResponse = ref(null)
    const isUpdatingTermsAndConditions = ref(false)

    const auth = useAuth({
        api,
        onSuccess: async(result) => {
            authResponse.value = result
        },
        onInitSuccess: async(result) => {
            authResponse.value = result
        }
    })

    const isGlobalAdmin = computed(() => {
        const grants = authResponse.value?.user?.role?.grants
        if(!grants){
            return false
        }

        let usersGrant = grants.find(grant => grant.service === 'users')
        if (usersGrant && usersGrant.permissions.includes('global_admin')){
            return true
        }
        return false
    })

    const hasServicePermission = computed(() => (service, allowed = []) => {
        // Always allow global_admin to anything, if present at the users service
        if(isGlobalAdmin.value){
            return true
        }

        const servicePermissionsList = authResponse.value?.user?.servicePermissions
        if(!servicePermissionsList || !servicePermissionsList[service]){
            return false
        }

        if(typeof allowed === 'string'){
            allowed = [allowed]
        }

        return servicePermissionsList[service].some(permission => allowed.includes(permission))
    })

    const hasObjectPermission = computed(() => (object, allowed=[]) => {
        if(isGlobalAdmin.value){
            return true
        }

        if(!object?.objectPermissions){
            return false
        }

        return object.objectPermissions.some(permission => allowed.includes(permission))
    })

    async function acceptTermsAndConditions() {
        try {
            isUpdatingTermsAndConditions.value = true
            const updateId = authResponse.value?.user?._id
            if (updateId) {
                authResponse.value.user = await api.service('users').patch(updateId, {hasAcceptedTermsOfService: true});
            }
        } catch (e) {
            throw e
        } finally {
            isUpdatingTermsAndConditions.value = false
        }
    }

    return {
        hasServicePermission,
        hasObjectPermission,
        acceptTermsAndConditions,
        isGlobalAdmin,
        authResponse,
        isUpdatingTermsAndConditions,
        ...auth,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
