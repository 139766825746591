import BaseStore from '@rhdhv/vue-basestore'
import {pinia} from "@/modules/pinia.js";
const {api} = useFeathers()
const snackbarStore = useSnackbarStore(pinia)

const baseStore = new BaseStore('projects', api, snackbarStore);

export const useProjectStore = defineStore('project', {
    state: () => ({
        ...baseStore.state,
        project: {},
    }),
    actions: {
        ...baseStore.actions,
    },
})
