<script setup>
const authStore = useAuthStore()
const router = useRouter()
const route = useRoute()
const store = useProjectStore()
const { currentItem } = storeToRefs(store)

const onlyEnableTermsAndConditions = computed(() => !authStore.$state.user?.hasAcceptedTermsOfService)
</script>

<template>
  <v-list-item
      v-if="authStore.hasObjectPermission(currentItem, ['get_all', 'get_assigned'])"
      :disabled="onlyEnableTermsAndConditions"
      :active="route.name === 'project-info'"
      class="pt-3 pb-3 mb-0 ps-16 sub-item"
      @click="router.push({ name: 'project-info', params: { id: currentItem._id }})">
    Details
  </v-list-item>
  <v-divider></v-divider>

  <v-list-item
      v-if="authStore.hasObjectPermission(currentItem, ['get_all', 'get_assigned'])"
      :disabled="onlyEnableTermsAndConditions"
      :active="route.name === 'project-map'"
      class="pt-3 pb-3 mb-0 ps-16 sub-item"
      @click="router.push({ name: 'project-map', params: { id: currentItem._id }})">
    Map
  </v-list-item>
  <v-divider></v-divider>

  <v-list-item
      v-if="authStore.hasObjectPermission(currentItem, ['get_all', 'get_assigned'])"
      :disabled="onlyEnableTermsAndConditions"
      :active="route.name === 'project-polygons'"
      class="pt-3 pb-3 mb-0 ps-16 sub-item"
      @click="router.push({ name: 'project-polygons', params: { id: currentItem._id }})">
    Polygons
  </v-list-item>

  <v-divider></v-divider>

  <v-list-item
      v-if="authStore.hasObjectPermission(currentItem, ['get_all', 'get_assigned'])"
      :disabled="onlyEnableTermsAndConditions"
      :active="route.name === 'project-export-data'"
      class="pt-3 pb-3 mb-0 ps-16 sub-item"
      @click="router.push({ name: 'project-export-data', params: { id: currentItem._id }})">
    Export Data
  </v-list-item>
  <v-divider></v-divider>
</template>

<style scoped>
.sub-item::before {
  content: "";
  margin-left: 20px;
}
</style>
