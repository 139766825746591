<script setup>
import DocumentationButton from "./DocumentationButton.vue";

const router = useRouter()
const projectStore = useProjectStore()

const {currentItem: currentProject} = storeToRefs(projectStore)

const currentRoute = computed({
  get() {
    return router.currentRoute?.value?.name || '';
  },
})
</script>

<template>
  <v-toolbar density="compact" color="secondary" class="w-100">
    <div class="ml-4">
      <v-toolbar-title style="font-size: 18px; font-weight: lighter; font-family: Bahnschrift" class="">
        AIS Platform
      </v-toolbar-title>
    </div>
    <v-divider vertical class="ml-4"></v-divider>
    <v-breadcrumbs class="float-left">
      <v-breadcrumbs-item v-if="currentRoute.startsWith('project-')">
        <router-link :to="{ name: 'project-index' }" class="toolbar-text text-white">Projects</router-link>
      </v-breadcrumbs-item>

      <v-breadcrumbs-item v-if="currentRoute === 'user-index'">
        <router-link :to="{ name: 'user-index' }" class="toolbar-text text-white">Users</router-link>
      </v-breadcrumbs-item>

      <v-breadcrumbs-item v-if="currentRoute === 'settings-roles'">
        <router-link :to="{ name: 'settings-roles' }" class="toolbar-text text-white">Settings</router-link>
      </v-breadcrumbs-item>

      <v-breadcrumbs-item v-if="currentRoute.startsWith('polygon-')">
        <router-link :to="{ name: 'settings-roles' }" class="toolbar-text text-white">Polygons</router-link>
      </v-breadcrumbs-item>

      <v-breadcrumbs-item v-if="currentRoute === 'about'">
        <router-link :to="{ name: 'about'}" class="toolbar-text text-white">
          About
        </router-link>
      </v-breadcrumbs-item>

      <v-breadcrumbs-item v-if="currentRoute === 'reference-projects'">
        <router-link :to="{ name: 'reference-projects'}" class="toolbar-text text-white">
          Reference Projects
        </router-link>
      </v-breadcrumbs-item>

      <v-breadcrumbs-item v-if="currentRoute === 'proposals'">
        <router-link :to="{ name: 'proposals'}" class="toolbar-text text-white">
          Proposals
        </router-link>
      </v-breadcrumbs-item>

      <v-breadcrumbs-item v-if="currentRoute === 'terms-and-conditions'">
        <router-link :to="{ name: 'terms-and-conditions' }" class="toolbar-text text-white">
          Terms and Conditions
        </router-link>
      </v-breadcrumbs-item>

      <v-breadcrumbs-item v-if="currentRoute === 'exported-data-index'">
        <router-link :to="{ name: 'exported-data-index' }" class="toolbar-text text-white">
          Data Exports
        </router-link>
      </v-breadcrumbs-item>

      <template
          v-if="currentRoute.startsWith('project-') && currentProject?._id">
        <v-breadcrumbs-divider></v-breadcrumbs-divider>
        <v-breadcrumbs-item>
          <router-link class="text-white toolbar-text" :to="{ name: 'project-info', params: { id: currentProject?._id } }">
            {{ currentProject.name }}
          </router-link>
        </v-breadcrumbs-item>

        <v-breadcrumbs-divider></v-breadcrumbs-divider>

<!--        <v-breadcrumbs-item v-if="currentRoute === 'project-members'">-->
<!--          <router-link class="text-white toolbar-text"-->
<!--                       :to="{ name: 'project-members', params: { id: currentProject?._id } }">-->
<!--            Members-->
<!--          </router-link>-->
<!--        </v-breadcrumbs-item>-->

        <v-breadcrumbs-item v-if="currentRoute === 'project-info'">
          <router-link class="text-white toolbar-text"
                       :to="{ name: 'project-info', params: { id: currentProject?._id } }">
            Details
          </router-link>
        </v-breadcrumbs-item>

        <v-breadcrumbs-item v-if="currentRoute === 'project-polygons'">
          <router-link class="text-white toolbar-text"
                       :to="{ name: 'project-info', params: { id: currentProject?._id } }">
            Polygons
          </router-link>
        </v-breadcrumbs-item>

        <v-breadcrumbs-item v-if="currentRoute === 'project-map'">
          <router-link class="text-white toolbar-text"
                       :to="{ name: 'project-map', params: { id: currentProject?._id } }">
            Viewer
          </router-link>
        </v-breadcrumbs-item>

        <v-breadcrumbs-item v-if="currentRoute === 'project-export-data'">
          <router-link class="text-white toolbar-text"
                       :to="{ name: 'exported-data-index' }">
            Data Exports
          </router-link>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <DocumentationButton></DocumentationButton>
  </v-toolbar>
</template>

<style scoped>

.toolbar-text {
  font-size: 15px
}


.v-breadcrumbs-divider {
  opacity: 0.5
}

</style>
